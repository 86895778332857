.button {
  --text-color: theme("colors.gs.1000");
  --bg-color: theme("colors.gs.300");
  --hover-bg-color: theme("colors.gs.400");
  --cursor: pointer;
  --font-size: 14px;
  --font-weight: bold;
  --padding-top: 8px;
  --padding-bottom: 8px;
  --padding-left: 17px;
  --padding-right: 17px;
  --border-radius: 8px;
  --border-color: #ffffff1a;
  box-sizing: border-box;
}
.button[disabled] {
  --cursor: default;
  opacity: 0.3;
}
.button[data-icon-only="true"] {
  --padding-left: 12px;
  --padding-right: 12px;
}
.button[data-size="small"] {
  --font-size: 13px;
  --padding-top: 7px;
  --padding-bottom: 7px;
  --padding-left: 15px;
  --padding-right: 15px;
}
.button[data-size="large"] {
  --padding-top: 12px;
  --padding-bottom: 12px;
  --padding-left: 32px;
  --padding-right: 32px;
}
.button[data-fill="clear"] {
  --border-color: transparent;
}

.button:focus,
.button:focus-visible,
.button[data-fill="clear"]:focus,
.button[data-fill="clear"]:focus-visible {
  outline: none;
  --border-color: theme('colors.amber.300');
}


.button[data-color="prim"] {
  --text-color: var(--color-btn-main-text);
  --bg-color: var(--color-btn-main-bg);
  --hover-bg-color: var(--color-btn-main-hov);
}
.button[data-color="prim"][data-fill="clear"] {
  --text-color: var(--color-btn-main-bg);
  /* gs-100 */
  --hover-bg-color: rgba(242, 246, 252, 0.1);
}

.button[data-color="grey"] {
  --text-color: #fff;
  --bg-color: theme("colors.gs.800");
  --hover-bg-color: theme("colors.gs.900");
}
.button[data-color="grey"][data-fill="clear"] {
  --text-color: theme("colors.gs.600");
  /* gs-600 */
  --hover-bg-color: rgba(128, 140, 168, 0.1);
}

.button[data-color="dark"] {
  --text-color: #fff;
  --bg-color: theme("colors.gs.800");
  --hover-bg-color: theme("colors.gs.900");
}
.button[data-color="dark"][data-fill="clear"] {
  --text-color: theme("colors.gs.800");
  /* gs-800 */
  --hover-bg-color: rgba(42, 48, 62, 0.1);
}

.button {
  display: inline-flex;
  align-items: center;
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  border: 1px solid var(--border-color);
  font-family: var(--app-font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  cursor: var(--cursor);
  color: var(--text-color);
  background-color: var(--bg-color);
  transition: background-color 0.2s;
  line-height: var(--font-size);
}
.button svg[data-icon] {
  margin-right: var(--padding-left);
  color: var(--text-color);
}
.button[data-icon-only="true"] svg[data-icon] {
  margin-right: 0;
}
.button[data-fill="clear"] {
  background-color: rgba(0, 0, 0, 0);
}
.button:hover {
  background-color: var(--hover-bg-color);
}
.button[disabled]:hover {
  background-color: var(--bg-color);
}
.button[disabled][data-fill="clear"]:hover {
  background-color: rgba(0, 0, 0, 0);
}

.studio-animate-bounce {
  animation: animation 300ms ease-in-out;
}

@keyframes animation {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(0.95);
  }
  50% {
    transform: scaleX(0.95) scaleY(0.99);
  }
  75% {
    transform: scaleY(0.99);
  }
  100% {
    transform: scaleY(1);
  }
}

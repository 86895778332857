.box {
  margin-top: 20px;
  height: var(--dp-drop-box-height);
  width: var(--dp-drop-box-width);
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 12px;
  font-weight: medium;
  border: 1px dashed theme("colors.gs.800");
  background: theme("colors.gs.1000");
  border-radius: 5px;
  color: theme("colors.gs.700");
  font-style: italic;
}
.box[data-over="true"] {
  background: theme("colors.gs.900");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-main: var(--compass-color-main, theme("colors.primary.500"));
  --color-btn-main-bg: var(
    --compass-color-btn-main-bg,
    theme("colors.primary.500")
  );
  --color-btn-main-text: var(
    --compass-color-btn-main-text,
    /* gs-100 */ rgba(242, 246, 252, 0.9)
  );
  --color-btn-main-hov: var(
    --compass-color-btn-main-hov,
    theme("colors.primary.600")
  );

  --app-color-background: theme("colors.gs.1000");
  --app-text-color: theme("colors.gs.100");
  --app-font-family: theme("fontFamily.display");

  --dp-box-width: 330px;
  --dp-box-height: 64px;
  --dp-drop-box-width: 330px;
  --dp-drop-box-height: 64px;
}

.app-demo-registration a,
.app-demo-registration a:visited {
  @apply text-primary-400;
}

/* Compass dp-editor container */
div#dpeditor-container {
  height: 100%;
  width: 100%;
  color: var(--app-text-color);
}

.studio-rc-slider .rc-slider-mark {
  top: 28px;
  opacity: 0.5;
  font-size: 10px;
  font-weight: 600;
}
.studio-rc-slider.rc-slider {
  margin-top: 10px;
  margin-bottom: 30px;
}
.studio-rc-slider .rc-slider-handle.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px var(--color-main);
}
.studio-rc-slider .rc-slider-mark-text {
  @apply text-gs-700;
}
.studio-rc-slider .rc-slider-mark-text-active {
  @apply text-gs-100;
}

.ReactModalPortal {
  color: var(--app-text-color);
}
.ReactModal__Overlay {
  @apply z-50;
}
.ReactModal__Body--open > :not(.ReactModalPortal):not(.ReactSelectPortal) {
  @apply filter blur-sm;
}

.studio-tooltip-portal {
  @apply fixed top-0 left-0 visible;
  filter: none !important;
  z-index: 1000;
}

.studio-focusable {
  @apply border border-transparent;
}
.studio-focusable:focus,
.studio-focusable:focus-visible {
  border-color: theme("colors.amber.300") !important;
  outline: none;
}

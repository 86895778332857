.studio-form__group {
  margin-bottom: 20px;
}
label.studio-form__label,
label.studio-form__label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  font-weight: bold;
  padding: 0;
  @apply text-gs-400;
}
label.studio-form__label[for] {
  cursor: pointer;
}
.studio-form__label-info {
  margin-left: 8px;
  @apply text-gs-500;
}
.studio-form__label-info-description {
  letter-spacing: 0.36px;
  font-size: 12px;
  margin-top: 8px;
  font-weight: 400;
  max-width: 530px;
  @apply text-gs-600;
}

input[type="text"].studio-form__control,
input[type="password"].studio-form__control,
input[type="tel"].studio-form__control,
input[type="number"].studio-form__control,
textarea.studio-form__control,
select.studio-form__control {
  display: block;
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  border-radius: 8px;
  font-weight: 600;
  border: 1px solid #ffffff1a;
  margin: 0;
  @apply text-gs-400;
}
input[type="text"].studio-form__control,
input[type="password"].studio-form__control,
input[type="tel"].studio-form__control,
input[type="number"].studio-form__control,
textarea.studio-form__control {
  color: var(--color, theme("colors.gs.400"));
  background-color: theme("colors.gs.1000");
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 14px 16px;
  border: 1px solid #ffffff1a;
  @apply bg-gs-1000;
}
select.studio-form__control {
  position: relative;
  background-color: theme("colors.gs.1000");
  height: 48px;
  padding-left: 16px;
}
select.studio-form__control.studio-form__control--no-appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="text"].studio-form__control:-internal-autofill-selected,
input[type="password"].studio-form__control:-internal-autofill-selected,
input[type="tel"].studio-form__control:-internal-autofill-selected,
input[type="number"].studio-form__control:-internal-autofill-selected,
textarea.studio-form__control:-internal-autofill-selected {
  color: theme("colors.gs.400") !important;
  background-color: theme("colors.gs.1000") !important;
  background-image: none !important;
  appearance: none !important;
}
input[type="text"].studio-form__control::placeholder,
input[type="password"].studio-form__control::placeholder,
input[type="tel"].studio-form__control::placeholder,
input[type="number"].studio-form__control::placeholder,
textarea.studio-form__control::placeholder {
  color: theme("colors.gs.700");
}
select.studio-form__control {
  color: var(--color, theme("colors.gs.400"));
  display: block;
  width: 100%;
  max-width: 400px;
  font-size: 16px;
  border-radius: 5px;
  height: 36px;
  margin: 0;
  background-color: theme("colors.gs.1000");
  border: 1px solid #ffffff1a;
  height: 48px;
}
@-moz-document url-prefix() {
  select.studio-form__control {
    text-indent: -2px;
  }
}

input[type="text"].studio-form__control:focus,
input[type="password"].studio-form__control:focus,
input[type="tel"].studio-form__control:focus,
input[type="number"].studio-form__control:focus,
textarea.studio-form__control:focus,
select.studio-form__control:focus {
  @apply border-amber-300;
}

.studio-form__group--has-error input[type="text"].studio-form__control,
.studio-form__group--has-error input[type="password"].studio-form__control,
.studio-form__group--has-error input[type="tel"].studio-form__control,
.studio-form__group--has-error input[type="number"].studio-form__control,
.studio-form__group--has-error textarea.studio-form__control,
.studio-form__group--has-error select.studio-form__control {
  @apply border-red-500;
}

.studio-form__control:focus {
  box-shadow: none;
  @apply border-amber-500;
}
.studio-form__control:focus,
.studio-form__control:focus-visible {
  outline: none;
}

.studio-form-error {
  padding: 0 5px;
  margin-top: 5px;
  font-size: 12px;
  color: theme("colors.red.500");
  word-break: break-word;
  max-width: 100%;
  @apply text-red-500;
}
.studio-form-error {
  margin-bottom: 20px;
}

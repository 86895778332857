.wrap {
  position: relative;
}
.button {
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 10px;
  color: theme("colors.gs.600");
  font-weight: medium;
  display: flex;
  align-items: center;
}
.wrap--active .button,
.button:hover {
  @apply text-gs-100 opacity-90;
}
.buttonIconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.wrap--active .buttonIconWrap {
  border-radius: 10px;
  background: #d9770680 0% 0% no-repeat padding-box;
  border: 0.5px solid #ffffff4d;
}
.dropdown {
  position: absolute;
  top: 100%;
  padding: 8px;
  background-color: theme("colors.gs.900");
  box-shadow: 0px 8px 32px #00000052;
  border: 1px solid #ffffff1a;
  border-radius: 8px;
}
.item {
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @apply text-gs-100 opacity-90;
}
.item:hover {
  background-color: theme("colors.gs.800");
}
.itemIconWrap {
  width: 20px;
  font-size: 12px;
}
.itemTitle {
  font-size: 12px;
  letter-spacing: 0.24px;
  font-weight: medium;
}

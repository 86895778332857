@keyframes rotatingTaco {

    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }

}

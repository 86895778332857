.wrap {
  position: relative;
  width: var(--dp-drop-box-width);
}
.box {
  background-color: theme("colors.gs.900");
  border: 1px solid theme("colors.gs.800");
  border-radius: 5px;
  padding: 16px;
  width: var(--dp-box-width);
  height: var(--dp-box-height);
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: 4px;
  transition: background-color 0.3s;
  font-weight: 500;
  margin-top: 20px;
}
.box--clickable {
  cursor: pointer;
}
.box::after {
  position: absolute;
  top: -10px;
  left: -20px;
  width: 2px;
  height: calc(100% + 10px);
  background: #ccc;
}
.box::before {
  position: absolute;
  top: 16px;
  left: -20px;
  width: 17px;
  height: 2px;
  background: #ccc;
}
.box--clickable:hover {
  background-color: theme("colors.gs.800");
}
.box :global(.svg-inline--fa) {
  @apply text-gs-100 opacity-90;
}
.boxUnderlay {
  position: absolute;
  width: var(--dp-box-width);
  height: var(--dp-box-height);
  background-color: var(--color-main);
  border: 1px solid #ffffff1a;
  border-radius: 5px;
  top: 27px;
  left: 7px;
}

.wrap--disabled .box {
  cursor: default;
}
.wrap--active .box {
  @apply border-amber-300;
}
.wrap--dropbox-up::after,
.wrap--dropbox-down::after {
  position: absolute;
  content: " ";
  left: 0px;
  height: 2px;
  width: 100%;
  background: theme("colors.gs.500");
  border-radius: 1px;
}
.wrap--dropbox-up::after {
  top: 9px;
}
.wrap--dropbox-down::after {
  bottom: -11px;
}
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-right: 8px;
  @apply text-gs-100 opacity-90;
}
.titleText {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.removeBtn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 14px;
  opacity: 0;
  transition: 0.3s opacity;
  @apply text-gs-100 opacity-90;
}
.box:hover .removeBtn {
  opacity: 1;
}
.icon {
  margin-right: 8px;
  width: 32px;
  height: 32px;
  background: theme("colors.gs.800");
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  @apply text-gs-100 opacity-90;
}
.icon--active {
  @apply border border-solid border-outline-300 border-opacity-30 text-green-200 bg-green-400 bg-opacity-30;
}
.box--clickable:hover .icon {
  border: 0.5px solid #ffffff4d;
}
.arrowBtn {
  position: absolute;
  bottom: calc(var(--dp-box-height) / 2);
  transform: translateY(50%);
  left: calc(var(--dp-box-width) + 20px);
  cursor: pointer;
  transition: transform 0.3s;
}
.arrowBtn[data-rotated="true"] {
  transform: translateY(50%) rotate(-180deg);
}
.arrowBtn :global(.svg-inline--fa) {
  font-size: 14px;
  color: theme("colors.gs.500");
}

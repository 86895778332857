.branches {
  overflow-y: auto;
  margin-bottom: 10px;
  margin: 0 auto;
}
.branch {
  border-radius: 8px;
  margin-bottom: 10px;
}
.branch:last-child {
  margin-bottom: 10px;
}
.branchRemoveBtn {
  margin-left: 20px;
  color: theme("colors.gs.600");
  text-transform: lowercase;
  cursor: pointer;
  font-size: 12px;
}
.branch .branchInputLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
}

.branchConfig {
  overflow: hidden;
}
.branchConfigHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 14px;
  @apply text-gs-100;
}
.branchConfigHeaderIcon {
  margin-left: 15px;
  font-size: 12px;
  transition: transform 0.3s;
}
.branchConfigHeaderIcon[data-opened="true"] {
  transform: rotate(90deg);
}

.extensions {
}
.loader {
}
.list {
  margin: 0 auto;
  max-width: 400px;
  margin-top: 20px;
}
.listHeader {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}
.listItem {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.3s background-color;
}
.listItem:hover {
  background-color: #f3f3f3;
}
.header {
  padding: 20px;
  display: flex;
  flex-direction: row-reverse;
}

.elementWrap {
  position: relative;
  margin-left: 0px;
}
.elementWrap .elementWrap {
  margin-left: 50px;
}
.elementWrap::before,
.elementWrap::after {
  content: " ";
  left: 15px;
  @apply absolute border-0 border-l border-solid border-gs-800 w-0;
}
.elementWrap::before {
  top: 0px;
  height: 20px;
  @apply z-10;
}
.elementWrap::after {
  top: calc(20px + var(--dp-box-height));
  height: calc(100% - 20px - var(--dp-box-height));
  @apply z-10;
}
.elementWrap.elementWrap__beforeDropBox::after,
.elementWrap.elementWrap__dropBox::before {
  @apply border-dashed;
}
.elementWrap.elementWrap__dropBox::before {
  top: -20px;
}
.elementWrap.elementWrap__lastBranch::after,
.elementWrap.elementWrap__dropBox::after {
  @apply hidden;
}
.element,
.dropBox {
  @apply z-20;
}

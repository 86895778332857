.canvas {
  padding: 10px 20px 100px 20px;
  height: 100%;
  overflow-y: auto;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 0;
}
.canvas::-webkit-scrollbar {
  display: none;
}
.steps {
  margin-left: 10px;
  padding-left: 10px;
}
.boxWrap {
  margin-bottom: 10px;
}
.callBox {
  color: theme("colors.gs.500");
  font-weight: 700;
  line-height: 38px;
  font-size: 14px;
  padding-left: 10px;
}
.branchWrap {
  padding-left: 10px;
}

.rangeGroup {
  padding: 10px 0;
  border-radius: 8px;
  margin-bottom: 10px;
  max-width: none;
}
.rangeWrap {
  padding: 0;
  transition: max-height 0.3s;
  padding: 0 15px;
}
label.rangeLabelWrap {
  padding: 0 20px;
  margin-bottom: 0;
}

.advancedSettings {
}
.advancedSettingsHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 14px;
  @apply text-gs-100;
}
.advancedSettingsHeaderIcon {
  margin-left: 15px;
  font-size: 12px;
  transition: transform 0.3s;
}
.advancedSettings[data-opened="true"] .advancedSettingsHeaderIcon {
  transform: rotate(90deg);
}
.advancedSettingsContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}
.advancedSettings[data-opened="true"] .advancedSettingsContent {
  max-height: 400px;
}
.warning {
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 8px;
  margin-bottom: 16px;
  background: #2d2a1f 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff0d;
  max-width: 400px;
  @apply text-gs-100 text-opacity-90;
}

.studio-select {
  @apply text-base;
}
.studio-select .studio-select__control {
  @apply bg-gs-1000 border-solid border-outline-500 h-12 cursor-pointer rounded-lg;
}
.studio-select .studio-select__control:hover {
  @apply border-outline-500;
}
.studio-select .studio-select__control--menu-is-open,
.studio-select .studio-select__control--is-focused {
  @apply shadow-none;
}
.studio-select .studio-select__placeholder {
  @apply font-semibold text-gs-700;
}
.studio-select .studio-select__control--menu-is-open {
  @apply border-amber-300 hover:border-amber-300;
}
.studio-select .studio-select__indicator-separator {
  @apply hidden;
}
.studio-select .studio-select__single-value {
  @apply font-semibold text-gs-400;
}
.studio-select .studio-select__input {
  color: theme("colors.gs.400") !important;
  font-weight: theme("fontWeight.semibold") !important;
}
.studio-select .studio-select__clear-indicator {
  @apply hidden;
}
.studio-select .studio-select__value-container {
  padding-left: 14px;
}
.studio-select .studio-select__indicators {
}
.ReactSelectPortal .studio-select__menu {
  @apply border-solid border-outline-500 border bg-gs-900 rounded-lg overflow-hidden;
}
.ReactSelectPortal .studio-select__menu-list {
}
.ReactSelectPortal .studio-select__option {
  @apply cursor-pointer font-medium text-gs-100 text-base;
}
.ReactSelectPortal .studio-select__option--is-selected {
  @apply text-primary-500 bg-transparent;
}
.ReactSelectPortal .studio-select__option--is-focused,
.ReactSelectPortal .studio-select__option--is-focused:active {
  @apply bg-gs-800;
}
.ReactSelectPortal .studio-select__menu-notice--no-options,
.ReactSelectPortal .studio-select__menu-notice--no-options:active {
  @apply text-gs-500;
}

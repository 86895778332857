.dpEditor {
  width: 100%;
  height: 100vh;
  display: flex;
  background: theme("colors.gs.1000");
  @apply text-gs-100 opacity-90;
}
:global(#dpeditor-container) .dpEditor {
  height: 100%;
}

.canvas {
  height: 100%;
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
  position: relative;
}
.menu {
  height: 100%;
  overflow: hidden;
}
.nestedDroppable {
  margin-left: 20px;
}
.canvasButtons {
  display: flex;
  position: absolute;
  top: 10px;
  right: 25px;
}

.canvasButton {
  margin-right: 15px;
}
.canvasButton:last-child {
  margin-right: 0;
}

.menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 258px;
  background: theme("colors.gs.900");
  border-right: 1px solid theme("colors.gs.800");
  border-left: 1px solid theme("colors.gs.800");
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.menu::-webkit-scrollbar {
  display: none;
}
.heading {
  position: sticky;
  background-color: theme("colors.gs.900");
  top: 0px;
  z-index: 10;
}
.backButton {
  padding: 8px;
  margin: 16px 8px 0 8px;
  background-color: theme("colors.gs.900");
  border: none;
  text-align: left;
  color: theme("colors.gs.600");
  font-weight: bold;
  font-size: 11px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.backButton:hover {
  background-color: theme("colors.gs.800");
}
.backButtonIcon {
  margin-right: 10px;
}
.subMenuTitle {
  font-weight: bold;
  letter-spacing: 0px;
  font-size: 24px;
  margin: 10px 16px;
  @apply text-gs-100 opacity-90;
}
.resourcesFilter {
  margin: 0px 8px 8px 8px;
}

.menuItem {
  width: 240px;
  padding: 8px;
  margin: 0 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.menuItemIcon {
  width: 32px;
  height: 32px;
  background: theme("colors.gs.800");
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 8px;
  @apply text-gs-100 opacity-90;
}
.menuItemContent {
  max-width: 180px;
}
.menuItemTitle {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  @apply text-gs-100 opacity-90;
}
.menuItemTitleText {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menuItemSubTitle {
  font-size: 10px;
  font-weight: medium;
  color: theme("colors.gs.500");
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
  font-weight: 400;
}
.menuItemListIcon {
  margin-left: 8px;
  font-size: 12px;
  color: theme("colors.gs.700");
}
.menuItem:hover {
  background-color: theme("colors.gs.800");
}
.menuItem:hover .menuItemIcon {
  border: 0.5px solid #ffffff4d;
}

.searchInputWrap {
  position: relative;
  width: 100%;
  padding: 0 16px;
  margin-top: 16px;
  margin-bottom: 8px;
}
input.searchInput {
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  border: 1px solid #ffffff1a;
  background-color: theme("colors.gs.1000");
  border-radius: 4px;
  padding: 0px 8px;
  color: theme("colors.gs.100");
  position: relative;
}
input.searchInput:focus,
input.searchInput:focus-visible {
  border-color: theme("colors.amber.300");
  outline: none;
}
input.searchInput::placeholder {
  color: theme("colors.gs.600");
  opacity: 1;
}
input.searchInput::-ms-input-placeholder {
  color: theme("colors.gs.600");
}
.searchInputIcon {
  font-size: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  color: theme("colors.gs.600");
  cursor: pointer;
}
.emptyMessage {
  font-size: 14px;
}
